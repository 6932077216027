<template>
  <b-sidebar id="add-new-sidebar" :visible="isAddNewSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Nome Marca -->
          <validation-provider #default="validationContext" name="Nome Marca">
            <b-form-group label="Nome Marca" label-for="nome-marca">
              <b-form-input v-model="itemData.nome" name="nome-marca" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Informe o nome da marca" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Observação -->
          <validation-provider
              #default="validationContext"
              name="Observação"
          >
            <b-form-group
                label="Observação"
                label-for="Observação"
            >
              <b-form-input
                  id="observacao"
                  ref="Observação"
                  v-model="itemData.observacao"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Insira uma observação"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="Logo"
          > 
           <b-form-group
                label="Logo (opcional)"
                label-for="Observação"
            >
            <b-form-file
                v-model="itemData.logo"
                label="Logo"
                label-for="logo"
                :state="getValidationState(validationContext)"
                browse-text="Procurar"
                placeholder="Escolha um arquivo"
                drop-placeholder="Escolha um arquivo"
            />

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-2" type="submit">
              Salvar
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { alphaNum, email, required } from '@validations'
  import {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadio,
    BSidebar,
  } from 'bootstrap-vue'
  import { ref } from '@vue/composition-api'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import useItemList from './useItemList'
  // Notification
  import { useToast } from 'vue-toastification/composition'

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormFile,
      vSelect,
      BFormRadio,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewSidebarActive',
      event: 'update:is-add-new-sidebar-active',
    },
    props: {
      isAddNewSidebarActive: {
        type: Boolean,
        required: true,
      },
      titulo: {
        type: String,
        required: true,
      },

    },
    setup(props, { emit }) {
      const blankitemData = {
        marca: '',
        observacao: '',
        logo: [],

      }

      const {
        criarItem

      } = useItemList()
      const isAddNewSidebarActive = ref(false)
      // Use toast
      const toast = useToast()

      const itemData = ref(JSON.parse(JSON.stringify(blankitemData)))
      const resetitemData = () => {
        itemData.value = JSON.parse(JSON.stringify(blankitemData))
      }

      const onSubmit = () => {
        criarItem(itemData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
        })
      }

      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetitemData)

      return {
        itemData,
        onSubmit,
        refFormObserver,
        getValidationState,
        resetForm,
      }
    },
    data() {
      return {
        required,
      }
    },

  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
