<template>
  <div>
    <form-create :is-add-new-sidebar-active.sync="isAddNewSidebarActive" titulo="Nova Marca"
      @refetch-data="refetchData" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Marcas
      </h5>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Exibição</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />

          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Pesquisar..." />
              <b-button variant="success" @click="isAddNewSidebarActive = true">
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refItemListTable" class="position-relative" :items="fetchDados" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc" :busy="isLoad" striped>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(nome)="data">
          <div class="d-flex align-items-center">
            <b-avatar size="4.5em" :src="data.item.logo" :text="avatarText(data.item.nome)" :variant="'secondary'"
              class="avatar-with-shadow" />
            <div class="ml-1">{{ data.item.nome }}</div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown v-if="$can('atualizar', 'leads')" variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="editarItem(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalItens" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao id="modal-confirmacao" titulo="Excluir Item" :item-id="dadosItem.id" :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem" @click="modalExcluir(data.item)" />
    </b-card>
    <b-modal id="modal-editar" v-model="modalShow" :title="`Editar ${dadosItem.nome}`" size="lg">

      <b-form-group label="Nome" label-for="nome">
        <b-form-input id="nome" v-model="dadosItem.nome" name="nome" autofocus trim
          placeholder="Informe o nome da marca" />
      </b-form-group>
       <b-form-group label="Observação" label-for="observacao">
        <b-form-input id="observacao" v-model="dadosItem.observacao" name="observacao" trim
          placeholder="Informe a obervação" />
      </b-form-group>
       <b-form-group
                label="Logo (opcional)"
                label-for="Observação"
            >
            <b-form-file
                v-model="dadosItem.logo"
                label="Logo"
                label-for="logo"
                browse-text="Procurar"
                placeholder="Escolha um arquivo"
                drop-placeholder="Escolha um arquivo"
            />

            </b-form-group>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="md" class="float-left" @click="modalShow = false">
            Fechar
          </b-button>
          <b-button variant="success" size="md" class="float-right" @click="salvarItemEditado(dadosItem)">
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    BAvatar,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BMedia,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BFormGroup,
    BFormFile
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { avatarText } from '@core/utils/filter'
  import { onUnmounted, ref } from '@vue/composition-api'
  import itemStoreModule from '../itemStoreModule'
  import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
  import store from '@/store'
  import TooltipTextoCortado from '@/views/components/componentesPensou/TooltipTextoCortado.vue'
  import FormCreate from './FormCreate.vue'
  import useItemList from './useItemList'

  export default {
    components: {
      FormCreate,
      modalConfirmacao,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BDropdown,
      BDropdownItem,
      BPagination,
      BSpinner,
      vSelect,
      TooltipTextoCortado,
      BFormGroup,
      BFormFile
    },
    setup() {
      const ITEM_APP_STORE_MODULE_NAME = 'marca'

      // Register module
      if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, itemStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
      })

      const isAddNewSidebarActive = ref(false)

      const {
        fetchDados,
        tableColumns,
        perPage,
        currentPage,
        totalItens,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refItemListTable,
        refetchData,
        deleteItem,
        update,

      } = useItemList()

      return {
        isAddNewSidebarActive,
        deleteItem,
        fetchDados,
        tableColumns,
        perPage,
        currentPage,
        totalItens,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refItemListTable,
        refetchData,
        avatarText,
        update,

      }
    },
    data() {
      return {
        dadosItem: {
          id: '',
          nome: '',
          observacao: '',
        },
        modalShow: false,
      }
    },
    computed: {
      isLoad() {
        return this.$store.state['marca'].load
      },
    },

    methods: {
      editarItem(item) {
        this.modalShow = true
        this.dadosItem.nome = item.nome
        this.dadosItem.id = item.id
      },

      modalExcluir(item) {
        this.dadosItem = { ...item }
        this.$root.$emit('bv::show::modal', 'modal-confirmacao')
      },

      excluirItem(dados) {
        this.deleteItem(dados)
      },
      salvarItemEditado(item) {

        const itemEditado = { ...item }
        this.$root.$emit('bv::hide::modal', 'modal-editar')

        this.update(itemEditado).then(() => {
          this.refetchData()
        })

      },
    },

  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';

  .avatar-with-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .media {
    display: flex;
    align-items: center;
  }
</style>
