import axios from '@axios'

const urlModulo = '/marcas'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get(urlModulo, { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },
    store(ctx, itemData) {
      const formData = new FormData()

      if (itemData.nome) {
        formData.append('nome', itemData.nome)
      }
      if (itemData.observacao) {
        formData.append('observacao', itemData.observacao)
      }

      if (itemData.logo) {
        formData.append('logo', itemData.logo)
      }

      return new Promise((resolve, reject) => {
        axios
          .post(urlModulo, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      console.log(itemData, 'update')
      const formData = new FormData()

      if (itemData.nome) {
        formData.append('nome', itemData.nome)
      }

      if (itemData.observacao) {
        formData.append('observacao', itemData.observacao)
      }

      if (itemData.logo) {
        formData.append('logo', itemData.logo)
      }

      formData.append('_method', 'PUT')

      return axios.post(`${urlModulo}/${itemData.id}`, formData)
        .then(response => response)
        .catch(error => reject(error))
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlModulo}/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
