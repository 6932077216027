import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refItemListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {
    //   key: 'codigo',
    //   label: 'Código',
    //   sortable: true,
    // },
    // { key: 'logo' },
    {
      key: 'nome',
    },

    {
      key: 'observacao',
      label: 'Observação',
    },
    {
      key: 'Ações',
      class: 'text-center',
    },
  ]
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('nome')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refItemListTable.value
      ? refItemListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refItemListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('marca/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar as marcas',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteItem = id => {
    store.dispatch('marca/deleteItem', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Marca excluída com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }

  const criarItem = dataItem => {
    return new Promise((resolve, reject) => {
      store.dispatch('marca/store', dataItem)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Marca adicionada com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          resolve(response);
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao adicionar a marca',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          reject(error);
        });
    });
  }

  const update = dataItem => {
    return new Promise((resolve, reject) => {
      store.dispatch('marca/update', dataItem)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Marca atualizada com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          resolve(response);
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao editar a marca',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          reject(error);
        });
    });
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refItemListTable,
    refetchData,
    criarItem,
    update
  }
}
